<template>
  <div class="container">
    <div class="my-3 text-center card">
      <Avatar size="large" icon="pi pi-ban" />
      <div class="text-2xl font-bold text-error my-3">
        Your account is not active
      </div>
      <div class="text-primary">
        Please contact the system administrator for more information
      </div>
      <hr />
      <router-link to="/">
        <Button label="Continue" icon="pi pi-home" />
      </router-link>
    </div>
  </div>
</template>
<script>
	export default {
		props: {},
		data: function () {
			return {
			};
		},
		methods: {},
	};
</script>